@import "@liveblocks/react-ui/styles.css";

.lb-root {
  --lb-accent: var(--rs-color-border-primary);
  --lb-foreground-secondary: var(--rs-color-foreground-neutral);
  --lb-foreground-moderate: var(--rs-color-foreground-neutral);
  --lb-foreground-tertiary: var(--rs-color-foreground-neutral-faded);
  --lb-dynamic-background: var(--rs-color-background-elevation-overlay);
  --lb-foreground-subtle: var(--rs-color-border-neutral-faded);
  --lb-elevation-shadow: 0 0 0 1px var(--rs-color-border-neutral-faded),0 2px 6px #00000014,0 8px 26px #0000001f;
  @apply text-body text-neutral;
}

.lb-emoji-picker-emoji {
  @apply text-[16px];
}

.lb-tooltip {
  @apply text-body-small bg-black border-neutral-faded border rounded-small px-8 py-4 font-semibold text-white;
}

.lb-dropdown-item {
  @apply text-body;
}

.lb-emoji-picker-category-header-title {
  @apply text-[10px];
}

.lb-comment-action,
.lb-button.lb-composer-editor-action {
  --lb-foreground-moderate: var(--rs-color-foreground-neutral);
  --lb-button-background: var(--rs-color-background-neutral) !important;
  --lb-icon-weight: 1.1;
  --lb-button-radius: 5px;
}

.lb-comment-author {
  @apply text-body-small font-bold;
}

.lb-comment-date-created {
  @apply text-body-small font-semibold;
}

.lb-comment-header {
  @apply pt-8;
}

.lb-sn-comment-first .lb-comment-body {
  @apply pr-24;
}

.lb-comment:where([data-editing]) .lb-composer {
  @apply m-0;
}

.lb-comment:where([data-editing]) .lb-comment-content .lb-composer-editor {
  @apply !m-0 border-0 !pl-0 !pr-24 !pt-0;
}

.lb-comment:where([data-editing]) .lb-composer-footer {
  @apply !m-0 !pr-12;
}

.lb-comment-details-labels {
  margin-inline-start: calc(
    var(--lb-comment-avatar-size) + 0.5 * var(--lb-spacing)
  );
}
.lb-comment .lb-comment-content {
  padding-inline-start: calc(
    var(--lb-comment-avatar-size) + 0.5 * var(--lb-spacing)
  );
}

.lb-comment-action:hover,
.lb-button.lb-composer-editor-action:hover {
  color: var(--rs-color-foreground-neutral);
}

.transition-lb-opacity {
  transition: opacity var(--lb-transition-duration) var(--lb-transition-easing);
}

.lb-comment:not(.lb-inbox-notification-comment) {
  @apply mx-8 p-0 py-4;
}
/* Global overrides for avatar positioning */
.lb-sn-user-avatar {
  @apply absolute left-12 top-12;
}

.highlighted-thread .lb-comment:not(.lb-inbox-notification-comment),
.highlighted-thread .lb-composer-footer:not(.lb-inbox-notification-comment),
.highlighted-thread .lb-composer-editor:not(.lb-inbox-notification-comment) {
  @apply bg-transparent;
}

.lb-composer-footer {
  @apply p-0;
}

.lb-comment-content .lb-composer-editor {
  @apply mb-8 ml-8 mr-12 mt-16 !pb-0 !pl-[6px] !pr-0 !pt-[2px];
}

.lb-comment-content .lb-composer-footer {
  @apply mb-8 ml-8 mr-12 mt-8 !p-0;
}

.lb-composer-editor [data-slate-placeholder="true"] {
  @apply text-neutral-placeholder;
}

.lb-composer-editor {
  @apply border-neutral-faded border-t px-4 pb-0 pt-8;
}

.lb-sn-thread-popover .lb-composer-editor {
  @apply border-t-0;
}

.composer-initial-comment .lb-composer-editor {
  @apply mt-0 border-t-0;
}

/* Reaction buttons */
.lb-comment-reaction {
  @apply text-body-small h-[24px] gap-4 py-2 pl-[6px] pr-8 font-semibold;
}
.lb-comment-reaction-count {
  @apply text-body-small font-semibold;
}

.lb-comment-reaction-add {
  --lb-icon-weight: 1.1;
  --lb-foreground-moderate: var(--rs-color-foreground-neutral);
  box-shadow: unset !important;
  @apply !bg-elevation-overlay;
}

.lb-comment-reaction-add:hover {
  background: var(--rs-color-background-neutral) !important;
  color: var(--rs-color-foreground-neutral);
}

.lb-comment-reaction:where([data-self]) {
  box-shadow: inset 0 0 0 1px var(--rs-color-border-primary);
}

.lb-button.lb-composer-action {
  @apply rounded-small;
}

/* We need to set backround like this with specifying button. Otherwise the specificity of the selector is same as tailwind selector for submit button which is overriding styles. It happens only in production build. */
button.lb-button.lb-composer-action {
  background: var(--lb-button-background);
}

.lb-dropdown-item {
  @apply text-neutral rounded-small;
}

.lb-dropdown-item:hover {
  @apply bg-neutral;
}

.lb-portal.lb-elevation.lb-dropdown {
  @apply rounded;
}

.lb-dropdown-item-icon {
  @apply text-neutral-faded;
}

.lb-portal {
  @apply z-50;
}

.lb-dropdown-item {
  @apply text-body;
}

/*
Notification popover
 */
.lb-inbox-notification-comments .lb-comment-content {
  @apply p-0;
}

.lb-inbox-notification-header {
  @apply mt-0;
}

.lb-inbox-notification-avatar {
  --lb-avatar-radius: var(--rs-unit-base);
}

.lb-inbox-notification-aside {
  --lb-inbox-notification-aside-size: 24px;
}

.lb-inbox-notification-list-item {
  @apply border-neutral-faded border-b;
}

.lb-inbox-notification-unread-indicator {
  --lb-accent: var(--rs-color-background-critical);
}

.lb-inbox-notification,
.lb-inbox-notification-list-item {
  @apply transition-opacity;
}

.lb-inbox-notification-list-item:has(
    .lb-inbox-notification:not([data-unread])
  ) {
  @apply opacity-50;
}

.lb-inbox-notification-list-item:has(
    .lb-inbox-notification:where([data-unread])
  ) {
  @apply opacity-100;
}

.lb-inbox-notification-list-item:hover {
  @apply !opacity-100;
}

.lb-inbox-notification:where([data-unread]) {
  --lb-dynamic-background: var(--rs-color-background-elevation-base);
}

.lb-inbox-notification:not([data-unread]) {
  --lb-dynamic-background: var(--rs-color-background-neutral-faded);
}

.lb-inbox-notification:hover {
  --lb-dynamic-background: var(--rs-color-background-neutral);
}

/* Hide the avatar, because we show our own  */
.lb-avatar.lb-comment-avatar {
  visibility: hidden;
}
.lb-avatar.lb-inbox-notification-avatar {
  visibility: hidden;
}

/*
  Suggestions
 */
.lb-composer-mention-suggestion-avatar {
  display: none;
}

.lb-composer-mention-suggestion-list,
.lb-composer-suggestions-list {
  --lb-foreground-subtle: var(--rs-color-background-neutral) !important;
}

.lb-composer-suggestions-list-item,
.lb-dropdown-item {
  @apply text-body px-8 py-4 !font-semibold;
}

.lb-composer-mention-suggestions,
.lb-emoji-picker-grid > div /* Emoji picker virtualized grid */ {
  scrollbar-width: thin;
  scrollbar-color: var(--rs-color-background-neutral-highlighted) transparent;
}
/* For WebKit */
.lb-composer-mention-suggestions::-webkit-scrollbar,
.lb-emoji-picker-grid > div::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.lb-composer-mention-suggestions::-webkit-scrollbar-track,
.lb-emoji-picker-grid > div::-webkit-scrollbar-track {
  background-color: transparent;
}

.lb-composer-mention-suggestions::-webkit-scrollbar-thumb,
.lb-emoji-picker-grid > div::-webkit-scrollbar-thumb {
  background-color: var(--rs-color-background-neutral-highlighted);
  /*border: 2px solid transparent;*/
  border-radius: 100px;
  background-clip: content-box;
}

.lb-composer-mention-suggestions::-webkit-scrollbar-thumb:hover,
.lb-emoji-picker-grid > div::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.lb-composer-mention-suggestions::-webkit-scrollbar-thumb:active,
.lb-emoji-picker-grid > div::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.7);
}

/*
  Mentions
 */
.lb-comment-mention {
  @apply text-neutral;
}

.lb-comment-mention:where([data-self]) {
  @apply text-primary;
}

.comment-highlight .comment-highlight-decoration,
.comment-highlight-pending-decoration {
  @apply inline border-b border-solid border-[#A1620740] bg-[#FEF9C366];
}

.comment-highlight .comment-highlight-hover-decoration,
.comment-highlight .comment-highlight-active-decoration {
  @apply border-[#A16207B2] bg-[#fef9c37a];
}

/* DM theme is overriding the lb styles so it has to be important */
.lb-attachment-delete {
  background: var(--lb-foreground-subtle) !important;
  color: var(--lb-foreground-secondary) !important;
}

.lb-attachment-delete:where(:hover, :focus-visible) {
  background: var(--lb-foreground-secondary) !important;
  color: var(--lb-dynamic-background) !important;
}

.lb-comment {
  @apply !bg-transparent;
}

.lb-composer {
  @apply !bg-transparent;
}

.lb-comment-link,
.lb-composer-link,
.lb-comment-link:hover,
.lb-composer-link:hover {
  color: var(--rs-color-foreground-primary) !important;
  text-decoration-color: var(--rs-color-foreground-primary-faded) !important;
}
